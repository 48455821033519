<template>
  <div class="home container">
    <LangButton />
    <DownloadButton />
    <TitleTop />
    <Intro class="home__intro" />
    <DownloadApp />
    <Features />
    <Articles />
    <Social />
    <DownloadApp :is-last="true" />
  </div>
</template>

<script>
import Intro from "@/components/home/Intro";
import Features from "@/components/home/Features";
import Social from "@/components/home/Social";
import DownloadApp from "@/components/home/DownloadApp";
import Articles from "@/components/home/Articles";
import DownloadButton from "@/components/elmts/DownloadButton";
import LangButton from "@/components/elmts/LangButton";
import TitleTop from "../components/elmts/TitleTop.vue";

export default {
  name: 'HomeView',
  components: {
    Intro,
    DownloadApp,
    Features,
    Social,
    Articles,
    DownloadButton,
    LangButton,
    TitleTop
  }
};
</script>

<style lang="scss" scoped>
  .home {
    margin-bottom: 100px;
    &__intro {
      margin-top: 360px;
      z-index: 1;
    }
  }

  @include breakpoint(1279) {
    .home {
      &__intro {
        margin-top: 340px;
      }
    }
  }

  @include breakpoint(500) {
    .home {
      &__intro {
        margin-top: 360px;
      }
    }
  }
</style>
